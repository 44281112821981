.list {
    user-select: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 25px;
}
  
.item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    padding: 5px;
    cursor: grab;
    border-radius: 10px;
    border-style: solid;
    border-color: silver;
}
  
.dragged {
    background-color: rgba(255, 255, 255, 0.9);
}

.fixed {
    background-color: rgba(220, 220, 220);
}
  